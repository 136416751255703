import React, { useEffect, useState } from "react";
import Background from "../components/Background/Background";
import Header from "../components/Header/Header";
import SimpleBanner from "../components/SimpleBanner/SimpleBanner";
import AppointmentTextForm from "../components/AppointmentTextForm/AppointmentTextForm";
import InfiniteSlider from "../components/infiniteSlider/infiniteSlider";
import content from "../content.json";
import { Element } from "react-scroll";
import About from "../components/About/About";
import BtnBanner from "../components/BtnBanner/BtnBanner";
import Slider from "../components/Slider/Slider";
import FeedbackForm from "../components/FeedbackForm/FeedbackForm";
import Contacts from "../components/Contacts/Contacts";
import Footer from "../components/Footer/Footer";
import VideoBanner from "../components/VideoBanner/VideoBanner";
import { Spinner } from "react-bootstrap";
import PortfolioSlider from "../components/PortfolioSlider/PortfolioSlider";
import SalonSlider from "../components/SalonSlider/SalonSlider";
import Reviews from "../components/Reviews/Reviews";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const [load, setLoad] = useState(false);
  useEffect(() => {
    window.addEventListener("load", function (event) {
      setLoad(true);
    });
    setTimeout(() => {
      if (load === false) {
        setLoad(true);
      }
    });
  });

  if (load) {
    return (
      <>
        <Helmet>
          <title>Салон красоты в Казани - Масик</title>
          <meta
            name="description"
            content="Салон красоты Масик – это место, где стиль, забота и профессионализм встречаются, чтобы подчеркнуть вашу естественную красоту. Мы предлагаем широкий спектр услуг по уходу."
          />
          <link rel="canonical" href="https://macik.beauty/" />
        </Helmet>

        <Background />
        <Header />
        <VideoBanner/>
        <SimpleBanner />
        <AppointmentTextForm />
        <InfiniteSlider />
        <Element name={"about"} id="about" />
        <About />
        <BtnBanner />
        <Element name={"services"} id="services" />
        <div className={"ContentTitle"}>
          <h2>Услуги</h2>
        </div>
        <Slider data={content.services.head} />
        <Slider data={content.services.makeup} />
        <Slider data={content.services.hand} />
        <Slider data={content.services.feet} />
        <Slider data={content.services.brows} />
        <div className={"ContentTitle"}>
          <h2>Наши работы</h2>
        </div>
        <PortfolioSlider data={content.services} />
        <Element name={"masters"} id="masters" />
        <Slider variant={2} data={content.masters} />
        <SalonSlider />
        <Reviews/>
        <FeedbackForm />
        <Element name={"contacts"} id="contacts" />
        <Contacts />
        <Footer />
      </>
    );
  } else {
    return (
      <div className={"Spinner_box"}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
};

export default Home;
