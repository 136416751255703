import React, { useEffect, useState } from 'react';
import './ModalNotify.css'
import HeartImg from "./img/heart.svg";
import ReactInput from "input-format/react";
import { parseDigit, templateFormatter, templateParser } from "input-format";
const TEMPLATE = '+x (xxx) xxx-xx-xx'
const parse = templateParser(TEMPLATE, parseDigit)
const format = templateFormatter(TEMPLATE)


const ModalNotify = ({ active, setActive }) => {


    return (
        <div className={active ? 'modal-notify active' : 'modal-notify'} onClick={() => setActive(false)}>
            <div className='modal-notify__background'></div>
            <div className='modal-notify__progress-bar'></div>
            <div className='modal-notify__content' onClick={e => e.stopPropagation()}>
                <div className='modal-notify__heart-col'>
                    <img src={HeartImg} className='modal-notify__heart'></img>
                    <div className='modal-notify__title'>Заявка успешно отправлена!</div>
                </div>
                <div className='modal-notify__subtitle'>Мы свяжемся с вами в ближайшее время!</div>
            </div>
        </div>
    );
};

export default ModalNotify;