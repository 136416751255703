import React, { useEffect, useRef } from 'react';
import './Reviews.css'; 

const Reviews = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.reviewlab.ru/widget/index-es2015.js";
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={'Reviews'}>
      <h2 className='SalonSlider__title__text'>Отзывы</h2>
      <review-lab data-widgetid="672c91a2eb335ce8833dc581"></review-lab>
    </div>
  );
};

export default Reviews;
