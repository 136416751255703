import React, { useState, useEffect } from 'react';
import './simpleBanner.css'

const SimpleBanner = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIsVisible(entry.isIntersecting),
            { threshold: 0.1 }
        );

        const banner = document.querySelector('.SimpleBanner');
        if (banner) observer.observe(banner);

        return () => {
            if (banner) observer.unobserve(banner);
        };
    }, []);

    return (
         <div className={`SimpleBanner ${isVisible ? 'visible' : ''}`}>
            <h1>
                САЛОН КРАСОТЫ В САМОМ СЕРДЦЕ КАЗАНИ
            </h1>
        </div>
    );
};

export default SimpleBanner;