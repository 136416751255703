import React, { useState, useEffect } from 'react';
import './VideoBanner.css'

const VideoBanner = () => {

    return (
        <div className='video-banner'>
            <video autoplay="autoplay" preload="auto" loop playsInline muted>
                <source type="video/mp4" src='/video/video-banner-mob.mp4' media="(max-width: 810px)" />
                <source type="video/mp4" src='/video/video-banner-desc.mp4' />
                I'm sorry; your browser doesn't support HTML video.
            </video>
        </div>
    );
};

export default VideoBanner;