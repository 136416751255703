import React from "react";
import "./MasterPortfolioSlider.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import content from "../../content.json";

function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

const MasterPortfolioSlider = ({ portfolio }) => {
  if (!portfolio || portfolio.length === 0) {
    return null;
  }

  const photos = portfolio.slice(0, 6);
  return (
    <div className={"portfolioSlider"}>
      <div className="Slider__title_v2">
        <p>Портфолио Мастера</p>
      </div>
      <NormalSpeedSlider photos={shuffle(photos)} />
    </div>
  );
};

const NormalSpeedSlider = ({ photos }) => (
  <Splide
    options={{
      width: "100%",
      arrows: false,
      pagination: false,
      autoWidth: true,
      gap: "20px",
      type: "loop",
      focus: "center",
      autoScroll: {
        pauseOnHover: false,
        pauseOnFocus: false,
        rewind: false,
        speed: 0.5,
      },
    }}
    extensions={{ AutoScroll }}
  >
    {photos.map((url, index) => (
      <SplideSlide key={index}>
        <div className={"slideImagePortfolio"}>
          <img src={url} alt={`Portfolio ${index}`} loading="lazy" />
        </div>
      </SplideSlide>
    ))}
  </Splide>
);

export default MasterPortfolioSlider;
