// ProductModal.js
import React from "react";
import "./ProductModal.css";

const ProductModal = ({ product, onClose }) => {
  if (!product) return null;
    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal__title-block">
            <h2 className="modal__title">{product.title}</h2>
            <div
              className="modal__close"
              onClick={() => onClose(product.title)}
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.85967 34.1397C3.94947 32.2947 2.42582 30.0878 1.37764 27.6478C0.329462 25.2077 -0.222262 22.5833 -0.245339 19.9277C-0.268415 17.2721 0.237619 14.6385 1.24324 12.1806C2.24885 9.72266 3.73392 7.48962 5.61177 5.61177C7.48962 3.73392 9.72266 2.24885 12.1806 1.24324C14.6385 0.237619 17.2721 -0.268415 19.9277 -0.245339C22.5833 -0.222262 25.2077 0.329462 27.6478 1.37764C30.0878 2.42582 32.2947 3.94947 34.1397 5.85967C37.7828 9.63171 39.7987 14.6838 39.7532 19.9277C39.7076 25.1716 37.6042 30.1879 33.896 33.896C30.1879 37.6042 25.1716 39.7076 19.9277 39.7532C14.6838 39.7987 9.63171 37.7828 5.85967 34.1397ZM8.67967 31.3197C11.6819 34.3219 15.7538 36.0086 19.9997 36.0086C24.2455 36.0086 28.3174 34.3219 31.3197 31.3197C34.3219 28.3174 36.0086 24.2455 36.0086 19.9997C36.0086 15.7538 34.3219 11.6819 31.3197 8.67967C28.3174 5.67742 24.2455 3.99077 19.9997 3.99077C15.7538 3.99077 11.6819 5.67742 8.67967 8.67967C5.67742 11.6819 3.99077 15.7538 3.99077 19.9997C3.99077 24.2455 5.67742 28.3174 8.67967 31.3197ZM28.4797 14.3397L22.8197 19.9997L28.4797 25.6597L25.6597 28.4797L19.9997 22.8197L14.3397 28.4797L11.5197 25.6597L17.1797 19.9997L11.5197 14.3397L14.3397 11.5197L19.9997 17.1797L25.6597 11.5197L28.4797 14.3397Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <button className="modal__weight">{product.weight}</button>

          <div className="modal__description-block">
            <div className="modal__description">
              <div className="modal__line"></div>
              <h3 className="modal__description-title">Описание</h3>
              <p className="modal__description-text">{product.description}</p>

              <h3 className="modal__ingredients-title">Ингредиенты</h3>
              <p className="modal__ingredients-text">{product.ingredients}</p>
              <div className="modal__line"></div>
              {product.alert && (
              <div className="modal__alert">
                <div className="modal__alert-img">
                  <svg
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_1229_7795"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="44"
                      height="44"
                    >
                      <path
                        d="M22 42C24.627 42.0034 27.2287 41.4876 29.6556 40.4823C32.0826 39.477 34.2869 38.002 36.142 36.142C38.002 34.2869 39.477 32.0826 40.4823 29.6556C41.4876 27.2287 42.0034 24.627 42 22C42.0034 19.3731 41.4876 16.7714 40.4823 14.3444C39.4769 11.9175 38.0019 9.71312 36.142 7.85802C34.2869 5.99804 32.0826 4.52302 29.6556 3.5177C27.2287 2.51239 24.627 1.99661 22 2.00002C19.3731 1.99667 16.7714 2.51247 14.3444 3.51778C11.9175 4.52309 9.71312 5.99809 7.85802 7.85802C5.99809 9.71312 4.52309 11.9175 3.51778 14.3444C2.51247 16.7714 1.99667 19.3731 2.00002 22C1.99661 24.627 2.51239 27.2287 3.5177 29.6556C4.52302 32.0826 5.99804 34.2869 7.85802 36.142C9.71312 38.0019 11.9175 39.4769 14.3444 40.4823C16.7714 41.4876 19.3731 42.0034 22 42Z"
                        fill="white"
                        stroke="white"
                        stroke-width="4"
                        stroke-linejoin="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22 35C22.663 35 23.2989 34.7366 23.7678 34.2678C24.2366 33.7989 24.5 33.163 24.5 32.5C24.5 31.837 24.2366 31.2011 23.7678 30.7322C23.2989 30.2634 22.663 30 22 30C21.337 30 20.7011 30.2634 20.2322 30.7322C19.7634 31.2011 19.5 31.837 19.5 32.5C19.5 33.163 19.7634 33.7989 20.2322 34.2678C20.7011 34.7366 21.337 35 22 35Z"
                        fill="black"
                      />
                      <path
                        d="M22 10V26"
                        stroke="black"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </mask>
                    <g mask="url(#mask0_1229_7795)">
                      <path d="M-2 -2H46V46H-2V-2Z" fill="#C21602" />
                    </g>
                  </svg>
                </div>
                
                <div className="modal__alert-block">
                  <span class="bold-text">Уважаемый Гость, если у вас есть аллергия на какой-либо
                  продукт, пожалуйста, предупредите об этом вашего мастера, либо
                  администратора. </span>
                  <br></br>
                  <br></br>
                  <span class="bold-text">Коровье молоко в составе этого напитка можно заменить на:</span>
                  <br></br>
                  <br></br>
                  <span class="bold-text">Миндальное</span> - вкус получится более ореховый и
                  консистенция будет не настолько плотной, как с обычным молоком
                  <br></br>
                  <br></br>
                  <span class="bold-text">Кокосовое</span> - вкус кокоса будет ярко выражен и может забрать на
                  себя всё внимание. 
                  <br></br>
                  <br></br>
                  <span class="bold-text">Банановое</span> - вкус банана будет ярко выражен
                  и может забрать на себя всё внимание.
                </div>
              </div>)}
            </div>
            <img
              src={product.img_url}
              alt={product.title}
              className="modal__img"
            />
          </div>
        </div>
      </div>
    );
};

export default ProductModal;
