import logo from './img/logo.svg'
import React, {useEffect, useState} from 'react';
import "./css/OpenRegister.css"


import ReactInput from "input-format/react";
import { templateParser, templateFormatter, parseDigit } from 'input-format'
const TEMPLATE = '+x (xxx) xxx-xx-xx'
const parse = templateParser(TEMPLATE, parseDigit)
const format = templateFormatter(TEMPLATE)


const OpenRegister = () => {
    const [phone, setPhone] = useState('')
    const [sended, setsSended] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [error, setError] = useState(false)
    const [textBtn, setTextBtn] = useState('Я приду!')

    const sendHandler = ()=>{
        if(!sended){
            if ( document.getElementById('FeedbackForm__nameInput2').value === '' || phone === ''){
                setEmpty(true)
                setPhone('')
                document.getElementById('FeedbackForm__nameInput2').value = ''
                setTimeout(()=>{
                    setEmpty(false)
                },2000)
            }else{
                fetch('https://macik.beauty/api/mailer.php?name=' + document.getElementById('FeedbackForm__nameInput2').value + '&phone=' + phone + '&action=opening',
                    {
                        headers: {
                            'Accept': 'application/x-www-form-urlencoded;charset=UTF-8'
                        }
                    })
                    .then(response => response.text())
                    .then((val)=>{
                        if(val === 'ok'){
                            setsSended(true)
                        }else {
                            setError(true)
                            setTimeout(()=>{
                                setError(false)
                            },3000)
                        }
                    })
            }
        }
    }

    useEffect(() => {
        if(sended)setTextBtn('Заявка отправлена!')
        if(error) setTextBtn('Упс! Что то пошло не так..')
        if(empty) setTextBtn('Поля не заполнены!')
        if(!empty && !error && !sended) setTextBtn('Я приду!');
    }, [empty,error,sended]);


    return (
        <div className={'OpenRegister'}>
            <span className={'OpenRegister__background'}></span>
            <div className="OpenRegister__content">
                <img className={'OpenRegister__logo'} src={logo} alt="logo"/>
                <p className={'OpenRegister__text OpenRegister__textTop'}>
                    ЗАПИСАТЬСЯ НА ПРИЕМ
                </p>
                <div className={'FeedbackForm__form'}>
                    <input id={'FeedbackForm__nameInput2'} maxLength={80} placeholder={'Имя'} type="text"/>
                    <ReactInput
                        value={phone}
                        onChange={setPhone}
                        parse={parse}
                        format={format}
                        placeholder={'Телефон'}
                        inputMode={'tel'}
                    />
                    <button onClick={sendHandler}>{textBtn}</button>
                </div>
                <p className={'OpenRegister__text OpenRegister__textBottom'}>
                    Премиум салон красоты  <span style={{fontWeight:'800'}}>МАСИК</span>  <br/> Ежедневно 09:00 - 21:00
                </p>
                <p className={'OpenRegister__text OpenRegister__address'}>
                    Кремлевская 21, 3 этаж, вход со двора
                </p>
            </div>
        </div>
    );
};

export default OpenRegister;