import React from "react";
import "../pages/css/MenuBar.css";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import ProductBlock from "../components/ProductBlock/ProductBlock";
import content from "../content.json";
import { Helmet } from "react-helmet-async";

const MenuBar = () => {
  const products = content.products.cards;

  return (
    <>
      <Helmet>
        <title>Меню - Масик</title>
        <meta
            name="description"
            content="Отборные кофейные бленды и освежающие напитки для вашего
            удовольствия!"
          />
        <link rel="canonical" href="https://macik.beauty/vkusno" />
      </Helmet>

      <Header />
      <div className="menuBar">
        <div className="menuBar__contentBox">
          <div className="menuBar__main">
            <div className="menuBar__main-block">
              <h1 className="menuBar__title">МАСИК</h1>
              <h1 className="menuBar__subtitle">угощает</h1>
            </div>

            <div className="menuBar__heart">
              <svg
                width="124"
                height="114"
                viewBox="0 0 124 114"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M61.9995 10.6665C68.7342 4.4267 77.5723 0.95303 86.7533 0.9375C96.6778 0.9375 106.154 5.02 113.088 12.2075C119.989 19.4007 123.833 28.9886 123.812 38.9565C123.833 48.9244 119.989 58.5123 113.088 65.7055C108.511 70.4492 103.94 75.308 99.3458 80.184C90.0078 90.097 80.572 100.119 70.7683 109.555L70.751 109.578C68.3168 111.883 65.0713 113.135 61.7196 113.06C58.3679 112.986 55.181 111.592 52.8513 109.181L10.905 65.7055C-3.38371 50.8877 -3.38371 27.0252 10.905 12.2133C17.5095 5.2926 26.5731 1.2503 36.1351 0.960838C45.697 0.671376 54.9885 4.15802 61.9995 10.6665Z"
                  fill="#C21602"
                />
              </svg>
            </div>
          </div>
          <p className="menuBar__description">
            Отборные кофейные бленды и освежающие напитки для вашего
            удовольствия!
          </p>
        </div>

        <div className="products__contentBox">
          {products.map((product, index) => (
            <ProductBlock key={index} product={product} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MenuBar;
