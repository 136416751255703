import React, {useEffect, useState} from 'react';
import './ModalFeedbackForm.css'
import ReactInput from "input-format/react";
import {parseDigit, templateFormatter, templateParser} from "input-format";
import ModalNotify from '../ModalNotify/ModalNotify';
const TEMPLATE = '+x (xxx) xxx-xx-xx'
const parse = templateParser(TEMPLATE, parseDigit)
const format = templateFormatter(TEMPLATE)


const ModalFeedbackForm = ({active, setActive}) => {

    const [phone, setPhone] = useState('')
    const [sended, setsSended] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [error, setError] = useState(false)
    const [textBtn, setTextBtn] = useState('Отправить заявку')

    const [modalNotifyActive, setModalNotifyActive] = useState(false);

    const generateRandomId = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 5; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    };

    const sendHandler = (e)=>{
        e.preventDefault();
        const pseudoLeadId = generateRandomId();

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "UNIVERSAL_EVENT",
            eventName: "join_the_team_submit_form",
            pseudo_lead_id: pseudoLeadId 
        });

    if (!sended) {
        const name = document.getElementById('footer-feedback-form__input').value;
        if (name === '' || phone === '') {
            setEmpty(true);
            setPhone('');
            document.getElementById('footer-feedback-form__input').value = '';
            setTimeout(() => {
                setEmpty(false);
            }, 2000);
        } else {

            // Отправляем данные в Telegram
            const botToken = '7613750109:AAHE-mu2OstHtpo5GNn3zFYmV3oK0XGG7ZU'; // токен
            const chatId = '-1002404053640'; // chat_id
            const message = `Новая заявка:\nИмя: ${name}\nТелефон: +${phone} \nОбратный звонок`;

            fetch(`https://api.telegram.org/bot${botToken}/sendMessage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chat_id: chatId,
                    text: message,
                }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.ok) {
                    setsSended(true);
                    setActive(false);
                    setModalNotifyActive(true)
                    setTimeout(() => {
                        setModalNotifyActive(false)
                    }, 6000);
                } else {
                    setError(true);
                    setTimeout(() => {
                        setError(false);
                    }, 3000);
                }
            })
            .catch(() => {
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 3000);
            });

        }
    }
};

useEffect(() => {
    if(sended)setTextBtn('Заявка отправлена!')
    if(error) setTextBtn('Упс! Что то пошло не так..')
    if(empty) setTextBtn('Поля не заполнены!')
    if(!empty && !error && !sended) setTextBtn('Отправить заявку');
}, [empty,error,sended]);

useEffect(() => {
    if (phone.length === 1 && phone !== '7'){
        setPhone( '7' + phone)
    }
}, [phone]);

    return (
        <>
        <ModalNotify active={modalNotifyActive} setActive={setModalNotifyActive}/>
        <div className={active? 'modal-2 active' : 'modal-2'} onClick={() => setActive(false)}>
            <div className='modal-2__content' onClick={e => e.stopPropagation()}>
                <form className='footer-feedback-form'>
                    <div className='footer-feedback-form__title'>Остались вопросики?</div>
                    <div className='footer-feedback-form__subtitle'>Оставь свой номер, дорогая, я перезвоню тебе и отвечу на все твои вопросы</div>
                    <input  placeholder='Имя' id='footer-feedback-form__input'></input>
                    <ReactInput
                        value={phone}
                        onChange={setPhone}
                        parse={parse}
                        format={format}
                        placeholder={'Номер телефона'}
                        inputMode={'tel'}
                    />
                    <button onClick={sendHandler} className='footer-feedback-form__btn'>Отправить</button>
                    <div onClick={() => setActive(false)} className='footer-feedback-form__close-btn'>x</div>
                </form>
            </div>
        </div>
        </>
);
};

export default ModalFeedbackForm;