import React, {useEffect, useState} from 'react';
import './ModalForm.css'
import axios from "axios";
import {Button, Modal} from "react-bootstrap";
import ReactInput from "input-format/react";
import {parseDigit, templateFormatter, templateParser} from "input-format";
const TEMPLATE = '+x (xxx) xxx-xx-xx'
const parse = templateParser(TEMPLATE, parseDigit)
const format = templateFormatter(TEMPLATE)

const ModalForm = ({show,handleClose}) => {

    const [sended, setsSended] = useState(false)
    const [phone, setPhone] = useState('')
    const sendHandler = ()=>{
        document.getElementById('ModalShowBtn__nameInput').value = ''
        setPhone('')
        setsSended(true)
    }

    useEffect(() => {
        if (phone.length === 1 && phone !== '7'){
            setPhone( '7' + phone)
        }
    }, [phone]);


    return (
        <>
            <Modal show={show} onHide={handleClose} animation={true}
                   centered
            >
                <Modal.Header closeButton
                              style={{
                                  padding:'18px 40px',
                                  borderBottom:'none'
                              }}>
                    <Modal.Title style={{
                        padding:'15px 0',
                        fontFamily:'cygre',
                        fontSize: '1.8em',
                        fontWeight:'lighter',
                        borderBottom:'none'
                    }}><span style={{fontSize:'1.3em'}}>Всё для тебя, </span> <br/> от масика ;)</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    padding: '20px 40px',
                }}>
                        <div className={'ModalShowBtn__form'}>
                            <input id={'ModalShowBtn__nameInput'} maxLength={80} placeholder={'Имя'} type="text"/>
                            <ReactInput
                                value={phone}
                                onChange={setPhone}
                                parse={parse}
                                format={format}
                                placeholder={'Телефон'}
                                inputMode={'tel'}
                            />
                        </div>
                        Оставь свои данные и наш менеджер свяжется с тобой через 30 минут!
                </Modal.Body>
                <Modal.Footer className={'d-flex justify-content-center'}
                              style={{
                                  borderTop: 'none'
                              }}>
                    <Button style={{
                        borderRadius: '15px',
                        padding: '15px',
                        fontFamily: 'cygre',
                        fontSize: '1.2em',
                        borderTop: 'none'
                    }} className={'w-75 bor'} variant="danger" onClick={sendHandler}>
                        {(sended) ? 'Упс! Что то пошло не так..' : 'Записаться'}
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
);
};

export default ModalForm;