import React from "react";
import { Helmet } from "react-helmet-async";
import MasterDetail from "../components/MasterDetail/MasterDetail";
import OtherMasters from "../components/OtherMasters/OtherMasters";
import masterData from "../content.json";
import { useParams } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import MasterPortfolioSlider from "../components/MasterPortfolioSlider/MasterPortfolioSlider";

const MasterPage = () => {
  const { id } = useParams();
  const currentMaster = masterData.masters.cards.find(
    (master) => master.id === Number(id)
  );

  return (
    <div>
      <Helmet>
        <title>{currentMaster.title}</title>
        <meta
            name="description"
            content={currentMaster.services}
          />
        <link rel="canonical" href={`https://macik.beauty/master/${id}`} />
      </Helmet>
      <Header />
      <MasterDetail master={currentMaster} />
      <MasterPortfolioSlider portfolio={currentMaster.portfolio} />
      <OtherMasters masters={masterData.masters.cards} />
      <Footer />
    </div>
  );
};

export default MasterPage;
