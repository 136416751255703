import React from 'react';
import './header.css'
//import {Link} from "react-scroll";
import { Link as RouterLink } from 'react-router-dom';
import content from "../../content.json";

import { useLocation, useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { useEffect } from 'react';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname !== '/') {
            const hash = location.hash;
            if (hash) {
                navigate('/' + hash, { replace: true });
            }
        }
    }, [location, navigate]);

    return (
        <header className={'Header'}>
            <Link activeClass="active" className="Header__navigate__link-logo" to="/" spy={true} smooth={false} duration={500} aria-label="главная">
            <div className="Header__logo"></div>
            </Link>
            <nav className="Header__navigate">
                <Link activeClass="active" className="Header__navigate__link" to="/#about" spy={true} smooth={false} duration={500} >О нас</Link>
                <Link activeClass="active" className="Header__navigate__link" to="/#services" spy={true} smooth={false} duration={500} >Услуги</Link>
                <Link activeClass="active" className="Header__navigate__link" to="/#masters" spy={true} smooth={false} duration={500} >Мастера</Link>
                <Link activeClass="active" className="Header__navigate__link" to="/#contacts" spy={true} smooth={false} duration={500} >Контакты</Link>
                
            </nav>
            <div className="Header__contact">
                <a style={{textDecoration: 'none'}} href={"tel:" + content.phone}><p>{content.phone}</p></a>
                <p>{content.time_day + " " + content.time_in + " - " + content.time_out}</p>
            </div>
        </header>
    );
};

export default Header;