import React from 'react';
import './BtnBanner.css';
import ModalShowBtn from "../ModalShowBtn/ModalShowBtn";
const BtnBanner = () => {
    return (
        <div className={'BtnBanner'}>
            <div>
                <p className={'BtnBanner_title'}>Первый раз?</p>
                <p className={'BtnBanner_paragraph'}>Запишись и получи приветственный бонус в 1000₽</p>
                <ModalShowBtn/>
            </div>
        </div>
    );
};

export default BtnBanner;