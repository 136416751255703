import React from 'react';
import './About.css'


const About = () => {
    return (
        <div className={'About'} >
            <div className={"About__text"}>
                <h2 className="About__title">О нас</h2>
                <p className="About__paragraph">Добро пожаловать в <span className={'About__text_red'}>"Масик"!</span>  </p>
                <p className="About__paragraph">Наш салон – это не просто место, где вы обновите свой образ, это
                    пространство, где ваше внутреннее сияние обретает внешнее воплощение.</p>
                <p className="About__paragraph">Мы гордимся индивидуальным подходом к каждому гостю, ведь у каждого своя
                    история, свои мечты и свои потребности. Используя только лучшие материалы и новейшие методики, мы
                    стремимся подчеркнуть вашу уникальность и естественную привлекательность.</p>
                <p className="About__paragraph"> "Масик" – это уют, профессионализм и вдохновение. Мы создаем не просто
                    образы, мы дарим уверенность и хорошее настроение. Приходите, чтобы не просто преобразиться, но и
                    получить заряд позитивной энергии. В "Масик" мы знаем, как сделать ваш день особенным.</p>
            </div>
            <div className={"About__image"}>
            </div>
        </div>
    );
};

export default About;